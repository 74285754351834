import React from 'react'
import './index.css'
import { connect } from 'react-redux'
import {update} from "../../redux/user_redux";
@connect(
    state=>state.user,
    {update}
)
class Problem extends React.Component{
    constructor(){
        super();
        this.state = {
            dataReadList:[
                {
                    title:'问：课程内容是什么？难度如何？',
                    subTitle:'课程内容为四季教育一线教研团队精心改编的小学数学思维训练。课程难度中等偏上，适合学有余力的孩子。,'
                },
                {
                    title:'问：课程适合几年级的孩子？级别如何选择？',
                    subTitle:'本课程为小学数学思维训练内容，适合小学6-15岁的孩子。课程分为Lv1-Lv9九个级别。,'
                },
                {
                    title:'问：课程如何购买？',
                    subTitle:'关注服务号：“52数学网服务号”（微信号：t52shuxue），点击我要报名，选择需要报名的课程即可。,',
                    imageUrl:'./images/qrcode.png'
                },
                {
                    title:'问：购买课程后如何约课？',
                    subTitle:'',
                    outlinkBtn:'点击查看具体约课步骤',
                    /*tap:'/problem/lookcourse',*/
                    link:'http://mp.weixin.qq.com/s?__biz=MzU2NTg5MDYyMA==&mid=2247484969&idx=2&sn=426ca9c14d7167654bcc6509e81dc995&chksm=fcb59d7acbc2146ca65437544ad8fcea2ab94af1b637958c090b5ab340e8f8aa95a219ed8179&scene=0&xtrack=1#rd'
                },
                {
                    title:'问：我该如何上课？',
                    subTitle:'1.购买课程后选择上课时间段，系统会自动生成课程表。,2.添加52数学君微信，52数学君会帮助您做好上课准备，有任何其他问题也可以联系52数学君。',
                    outlinkBtn:'点击查看如何上课',
                    link:'http://mp.weixin.qq.com/s?__biz=MzU2NTg5MDYyMA==&mid=2247484969&idx=1&sn=0d3a6d6961ad90777bd78035a2f5543a&chksm=fcb59d7acbc2146ce2469dc63f4bcae7f6a23164262382f299a65d7a5fd407c5910f7a420554&scene=0&xtrack=1#rd'
                },
                /*{
                    title:'问：有讲义或配套练习吗？电子版还是纸质版？',
                    subTitle:'试听课是电子讲义（需自行打印），正式课是纸质版讲义，每个季度邮递一次，需要在服务号填写地址以后联系数学君。,若邮寄地址较远花费的时间可能较长，纸质教材在邮递过程中，您可以先使用电子版教材。',
                    subBtn:'如何在电脑上下载讲义？。',
                    subBtn1:'如何在苹果手机上下载讲义？',
                    recomend:'（推荐）',
                    tap:'/problem/handout',
                    tap1:'/problem/iospreview'
                 },*/
                {
                    title:'问：如果有事可以请假和调课吗？',
                    subTitle:'调课和请假需要提前最少一天，当天不支持调课和取消约课。,常规班一周内每个年级都上相同主题，建议与数学君沟通后在周内调整。取消约课不扣课时，但是无法看回放。'
                },
                {
                    title:'问：课程可以回放吗？',
                    subTitle:'直播支持回放，回放相较直播在学习效果上要打很大折扣，建议如非必要尽量参加直播。,回放主要功能是知识点查漏。',
                    outlinkBtn:'怎么查看回放？',
                    /*tap:'/problem/preview'*/
                    link:'http://mp.weixin.qq.com/s?__biz=MzU2NTg5MDYyMA==&mid=2247484969&idx=6&sn=1cb8a19d31ec22b2c3cfe3dfb4b17460&chksm=fcb59d7acbc2146c8ab36962aa16ab987f46ee3ef6f3832bf480c2424d6bbec8e742f948c915&scene=0&xtrack=1#rd'
                },
                {
                    title:'问：不满意可以退款吗？',
                    subTitle:'体验课和长期课不满意可以随时退款，但我们会扣除已消耗的课时费用。',
                    subBtn:'详细退费规则',
                    tap:'/problem/backrule'
                },
            ],
            showNav:true,
        };
        this.handleClick = this.handleClick.bind(this);

    }
    handleClick(tap){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.props.history.push(tap)
    }
    handleOutlinkClick(link){
        window.open(link);
    }
    render(){
        const isMobile = this.props.data.isMobile
        return(
            <div class="">
                <div class='container'>
                    <img id='fixed-set' src={require('./images/problem.png')} alt="" style={{"width": "auto"}}/>
                    <ul class="data-list">
                        {this.state.dataReadList.map((value,index)=>{
                            return(
                                <li key={index} class="data-item">
                                    <div class="data-title">
                                        <span class='data-title-num'>0{index+1}</span>
                                        <span class='data-title-text'>{value.title}</span>
                                    </div>
                                    {value.subTitle!=='' ?value.subTitle.split(',').map((inner,innerIndex)=>{
                                        return(
                                            <div class='data-subtitle' key={innerIndex}>{inner}</div>
                                        )
                                    }) :''}
                                    {value.hasOwnProperty('imageUrl') ? <div class="image-url"><img src={require('./images/qrcode.png')} alt=""/></div> :''}
                                    {value.hasOwnProperty('subBtn') ? <div class="recomend-btn" onClick={()=>this.handleClick(value.tap)}>{value.subBtn}{value.hasOwnProperty('recomend') ? <span class='recomend-text'>{value.recomend}</span>:""}</div> :''}
                                    {value.hasOwnProperty('subBtn1') ? <div class="recomend-btn" onClick={()=>this.handleClick(value.tap1)}>{value.subBtn1}</div>:''}
                                    {value.hasOwnProperty('outlinkBtn') ? <div class="recomend-btn" onClick={()=>this.handleOutlinkClick(value.link)}>{value.outlinkBtn}{value.hasOwnProperty('recomend') ? <span class='recomend-text'>{value.recomend}</span>:""}</div> :''}
                                </li>
                            )
                        })}

                    </ul>
                </div>
            </div>
        )
    }
}
export default Problem;