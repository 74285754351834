import React from 'react'
import './lookcourse.css'
import { connect } from 'react-redux'
import {update} from "../../../redux/user_redux";
@connect(
    state=>state.user,
    {update}
)
class backRule extends React.Component{
    render(){
        const isMobile = this.props.data.isMobile
        return (
            <div class={isMobile?'container_mobile_about':'container'}>
                <div class="spe_top"></div>
                <div class="rule">
                    <p class='title'>课程退课退费规则</p>
                    <p>1.退课规则</p>
                    <p>常规班型不满意可随时退课，非常规班型（如精智班、强化班、短期班）开课前可随时退课，开课后不支持退课。</p>
                    <p>2.退费规则</p>
                    <p>2.1退费金额</p>
                    <p>退费金额=（订单总课时-已消耗课时-剩余赠送课时)*课时单价。也就是说，已消耗课时不能退费，剩余赠送课时不能退费。</p>
                    <p>2.2退费条件</p>
                    <p>用户提出退费后须将发票寄回，并与上海弗兮网络科技有限公司按上述规则确认退费金额，金额确认后，上海弗兮网络科技有限公司30天内处理退费。</p>
                    <p>2.3退费方式</p>
                    <p>微信支付365天内的订单及支付宝支付90天内的订单，退课后退费可原路返还；</p>
                    <p>超过以上期限的订单只可采用银行转账方式退费，用户需提供银行储蓄卡信息，包括卡号、持卡人姓名、开户行（须具体到支行），退费15个工作日到账。</p>
                </div>
            </div>
        )
    }

}
export default backRule