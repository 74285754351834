import  React  from 'react'
import CommonHeader from '../topHeader'
import CommonFooter from '../bottomFooter'
import HeaderMobile from '../headerMobile'
import FooterMobile from '../footerMobile'
// import {globalData} from "../../redux/user_redux";
import { connect } from 'react-redux'
import {update} from "../../redux/user_redux";

const data = {

}
@connect(
    state=>state.user,
    {update}
)

class Layout extends React.Component{
    constructor(props){
        super(props);
        // console.log(props)
        this.state = {
            isMobile: this.props.data.isMobile
        }
    }
    componentDidMount() {
        // let w = window.innerWidth || document.body.clientWidth
        // if(w<=750) {
        //     this.setState({isMobile: true})
        // }else {
        //     this.setState({isMobile: null})
        // }
        window.addEventListener('scroll', this.handleScroll.bind(this));
        window.addEventListener('resize', this.handleResize.bind(this));
    }
    handleScroll(){
        // console.log('浏览器滚动事件');
    }
    handleResize() {
        let w = window.innerWidth || document.body.clientWidth
        if(w<=800) {
            this.setState({isMobile: true})
        }else {
            this.setState({isMobile: null})
        }
        // console.log(w)


    }
    render(){
        const isMobile = this.state.isMobile
        // console.log(isMobile)
        let HEADER = <CommonHeader/>
        let FOOTER = <CommonFooter/>
        if(isMobile) {
            // console.log(111)
            HEADER = <HeaderMobile />
            FOOTER = <FooterMobile />
        }
        return (
            <div id='wrapper'>
                {HEADER}
                <div id='layout-page' refs={'wrapper'}>{this.props.children}</div>
                {FOOTER}
            </div>
        )

    }
}
export default Layout;