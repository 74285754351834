// import 'babel-polyfill';
import React from 'react';
import { HashRouter  as Router, Switch,  Route } from 'react-router-dom'

import './App.css'
import './iconfont.css'
import Layout from './components/layout'
import Home from './pages/home';
import ProblemRouter from './pages/problem/router'
import SoftWare from './pages/software'
import TeacherTeamRouter from './pages/teacherTeam/router'
import About from './pages/about'
import Course from './pages/course'
class App extends React.Component {
    render(){
        let LayoutRouter = (
            <Layout>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path ='/problem' component={ProblemRouter} />
                    <Route path ='/software' component={SoftWare} />
                    <Route path ='/teacher' component={TeacherTeamRouter} />
                    <Route path ='/about' component={About} />
                </Switch>
            </Layout>
        );
        return (
            <div className="App" id='App'>
                <Router >
                    <Switch>
                        <Route path="/" render={ props => LayoutRouter}/>
                    </Switch>
                </Router>

            </div>
        );
    }


}

export default App;
