let _testUrl = window.location.origin
if (_testUrl.indexOf('localhost') >= 0) { // 测试环境
    _testUrl = 'http://test.52shuxue.com'
} else if (_testUrl.indexOf('uat.52shuxue.com') >= 0) { // uat
    _testUrl = 'https://uat.52shuxue.com'
} else if (_testUrl.indexOf('kiwi.52shuxue.com') >= 0) { // kiwi
    _testUrl = 'https://kiwi.52shuxue.com'      
} else if (_testUrl.indexOf('www.52shuxue') >= 0) { // 正式
    _testUrl = 'https://tars.52shuxue.com'
}

export const testUrl = _testUrl
export const setLocalStorage = (key, val) => {
    const dataType = typeof val;
    if (dataType === 'object') {
        localStorage.setItem(key, JSON.stringify(val));
    } else if (['number', 'string', 'boolean'].indexOf(dataType) >= 0) {
        const result = localStorage.setItem(key, val);
        return result;
    } else {
        /* eslint-disable */
        alert('该类型不能用于本地存储');
    }
};
/* eslint-enable */
/* eslint-disable */
export const getLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    if (data) {
        const result = localStorage.getItem(key);
        return result;
    }
};
/* eslint-disable */
export const removeLocalStorage = (key) => {
    const result = localStorage.removeItem(key);
    return result;
};