import React from 'react'
import './index.styl'
import {postData} from "../../../utils/httpset";
import { Modal } from 'antd';
class TeacherInfo extends React.Component{
    constructor(){
        super();
        this.state = {
            teacherInfoId:'',
            teacherInfoList:[],
            visible: false
        }
    }
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };
    getTeacherInfoList(){
        const params = {};
        params.teacher_info_ids = this.state.teacherInfoId;
        const postUrl = `eval/common/getTeacherInfoList`;
        const result = postData(postUrl, params);
        result.then((res)=>{
            this.getTeacherInfoListSucc(res)
        }).catch(()=>{

        })
    }
    getTeacherInfoListSucc(res){
        const result = res.data;
        const meta = result.meta;
        const errCd = meta.errCd;
        if( errCd === '' ){
            const data = result.data;
            const teacherInfoList = data.teacherInfoList;
            this.setState({teacherInfoList:this.state.teacherInfoList.concat(teacherInfoList)})

        }

    }
    componentDidMount() {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.setState({
            teacherInfoId: this.props.match.params.teacherId
        },()=>{
            this.getTeacherInfoList();
        });
    }
    render() {
        let  teacherInfoList = [];
        if( this.state.teacherInfoList.length ){
            teacherInfoList = this.state.teacherInfoList[0]
        }
        return (
            <div class='teacherTeams container'>
                <div class="teacherHeader">
                    教师简介
                </div>
                <div class="teacherInfos clearfix">
                    <img class='pull-left' src={teacherInfoList.teacherPic2Url} alt=""/>
                    <div class="teacherInfoDesc pull-left">
                        <div class="teacherName">{teacherInfoList.teacherName}</div>
                        <div class="teacherGrade">主教年级：{teacherInfoList.teacherMainGrade}年级</div>
                        {teacherInfoList.teacherOutline ? <div class="teacherDesc">
                            {teacherInfoList.teacherOutline}
                        </div>:'' }
                        <div class="reservations-wrapper clearfix">
                            <div class="reservations pull-left" onClick={()=> this.showModal()}>
                                我要约课
                            </div>
                            <Modal
                                title="我要约课"
                                width={280}
                                visible={this.state.visible}
                                onOk={this.hideModal}
                                onCancel={this.hideModal}
                                okText="确认"
                                cancelText="取消"
                            >
                                <div class="qrCode-wrapper">
                                    <img class="qrCode " src={require('./images/wangTeacher.png')} alt=""/>
                                    <p class="qrCode-text">关注52数学网服务号</p>
                                    <p class="qrCode-text">了解更多课程内容</p>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
                <div class="teacherHeader clearfix">
                    个人风采
                </div>
                <div class="personInfoVideo">
                    {teacherInfoList.selfIntroductionUrl ? <video controls src={teacherInfoList.selfIntroductionUrl}></video> : ''}
                </div>
                <div class="teacherHeader">
                    课程试听
                </div>
                <div class="personInfoVideo personVideo">
                    {teacherInfoList.experienceUrl ? <video controls src={teacherInfoList.experienceUrl}></video> : '' }
                </div>
            </div>
        )
    }
}
export  default TeacherInfo;