import React            from 'react';
import {  Switch, Redirect, Route } from 'react-router-dom'

import Problem from './index'
import LookCourse from './category/lookCourse';
import Handout from './category/handout'
import Preview from './category/preview'
import IosPreview from './category/iospreview'
import backRule from './category/backRule';
class ProblemRouter extends React.Component{
    render(){
        return(
            <Switch>
                <Route path="/problem/index" component={Problem}/>
                <Route path="/problem/lookcourse" component={LookCourse}/>
                <Route path="/problem/handout" component={Handout}/>
                <Route path="/problem/preview" component={Preview}/>
                <Route path="/problem/iospreview" component={IosPreview}/>
                <Route path="/problem/backrule" component={backRule}/>
                <Redirect exact from="/problem" to="/problem/index" />
            </Switch>
        )
    }

}
export default ProblemRouter