import React from 'react'
import { postData } from "../../utils/httpset";
import './index.styl'
import './index.css'
import AwesomeSwiper from 'react-awesome-swiper';
import { connect } from 'react-redux'
import {update} from "../../redux/user_redux";
const config = {
    width: 400,
    loop : true,
    autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
    },
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    speed: 1000,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        // bulletElement : 'li',
        // hideOnClick :true,
        clickable :true,
    },
    // on: {
    //     slideChange: function () {

    //     },
    // },
};
@connect(
    state=>state.user,
    {update}
)

class TeacherTeam extends React.Component{
    swiperRef = null;
    goNext = () => {//use `this.swiperRef.swiper` to get the instance of Swiper
        this.swiperRef.swiper.slideNext();
    }
    constructor(){
        super();
        this.state = {
            //teacherIds:'12,22,16,18,64,23',
            teacherIds:'22,18,23,21,72,215',
            teacherInfoList:[],
        }
        this.handleClickInfo = this.handleClickInfo.bind(this);
    }
    handleClickInfo(teacherInfoId){
        // document.documentElement.scrollTop = document.body.scrollTop = 0;
        // this.props.history.push(
        //     `/teacher/teacherinfo/${teacherInfoId}`);
    }
    getTeacherInfoList(){
        const postUrl = `eval/common/getTeacherInfoList`;
        const result = postData(postUrl, {});
        result.then((res)=>{
                this.getTeacherInfoListSucc(res)
        }).catch(()=>{
  
        })
    }
    getTeacherInfoListSucc(res){
        const result = res.data;
        const meta = result.meta;
        const errCd = meta.errCd;
        if( errCd === '' ){
            const data = result.data;
            const teacherInfoList = data.teacherInfoList.filter(v => v.teaQualificationCode);
            if(teacherInfoList.length>0){
                teacherInfoList.forEach((item,index)=>{
                    // QualificationCode明示
                    //if(item.teaQualificationCode.length<6){
                        item.teaQualificationCodestr=item.teaQualificationCode
                    //}else{
                    //    item.teaQualificationCodestr=item.teaQualificationCode.substr(0,2)+'****'+item.teaQualificationCode.substr(6,item.teaQualificationCode.length)
                    //}   
                })
            }
            // console.log(teacherInfoList)
            this.setState({teacherInfoList:this.state.teacherInfoList.concat(teacherInfoList)})

        }

    }
    componentDidMount(){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
       this.getTeacherInfoList();
        /*common/getTeacherInfoList*/
    }

    render() {
        const isMobile = this.props.data.isMobile
        let BANNER = <img className='index_01' src={require('./images/index_banner.png')} alt="" width="100%"/>
        if(isMobile) {
            BANNER = <div className='banner_mobile'>
                        <div className="text">
                            <div className="text_1">一线名师教研团队</div>
                            <div className="text_2">+教育行业权威专家</div>
                        </div>
                    </div>
        }
        return (
        <div class="">
            {BANNER}
            <div class={isMobile?'teacherTeamMobile container_mobile':'teacherTeam container'}>
               <div class="teacherHeader">
                   教研团队
               </div>
                {/* <AwesomeSwiper ref={this.swiperRef = 'ref'} config={config}> */}
                    <div class="swiper-wrapper teacherTeamBanner">
                        <div class="swiper-slide"> <div class='pull-left commanBanner'>
                            <div class="img-wrapper">
                                <img src={require('./images/zhangchi.png')} alt=""/>
                            </div>
                            <div class="teacherInfo-wrapper">
                                <div class="teacher-name">
                                    <span class='teacher-name-text'>张弛</span>
                                   {/* <span class='teacher-school'>上海师范大学</span>*/}
                                </div>
                                <div class="teacher-desc">
                                    丰富的一线教育经验，荣获市级教学评比佳绩。丰富的课程研发管理经验。
                                </div>
                            </div>
                        </div></div>
                        <div class="swiper-slide"> <div class='pull-left commanBanner'>
                            <div class="img-wrapper">
                                {/*<img src={require('./images/zhangchi.png')} alt="">*/}
                                <img src={require('./images/zhangxu.png')} alt=""/>
                            </div>
                            <div class="teacherInfo-wrapper">
                                <div class="teacher-name">
                                    {/*<span class='teacher-name-text'>张弛</span>*/}
                                    <span class='teacher-name-text'>张栩</span>
                                </div>
                                <div class="teacher-desc">
                                    {/*丰富的一线教育经验，荣获市级教学评比佳绩。丰富的课程研发管理经验。*/}
                                    英国格拉斯哥大学教育学硕士毕业。近十年教学经验，曾获得亚洲国际数学奥林匹克公开赛金牌教练员等荣誉称号，华东师范大学《非常数学夏令营》特聘研究员。
                                </div>
                            </div>
                        </div></div>
                        <div class="swiper-slide"> <div class='pull-left commanBanner'>
                            <div class="img-wrapper">
                                <img src={require('./images/fengjie.png')} alt=""/>
                            </div>
                            <div class="teacherInfo-wrapper">
                                <div class="teacher-name">
                                    <span class='teacher-name-text'>冯洁</span>
                                </div>
                                <div class="teacher-desc">
                                    多年的数学思维训练教学经验。功底扎实，教学生动严谨，耐心亲和。逻辑思维清晰，深入浅出。
                                </div>
                            </div>
                        </div></div>
                    </div>
                    {/* <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-pagination"></div> */}
                {/* </AwesomeSwiper> */}
                <div class="teacherHeader">
                   教师团队
                </div>
                <div class="excellentTeacher">
                    <ul class='clearfix excellentTeacherList'>
                        { this.state.teacherInfoList.map((val,index)=>{
                                return (
                                    <li class='excellentTeacherItem pull-left' key={index} onClick={()=>this.handleClickInfo(val.teacherInfoId)}>
                                        <img src={val.teacherPic2Url} alt=""/>
                                        <div class="teacherInfoPad clearfix">
                                            <div class="teacherInfo clearfix">
                                                <div class="pull-left teacher-name">{val.teacherName}</div>
                                                {/* <div class="pull-right teacher-grade">{val.teacherMainGrade}年级</div> */}
                                            </div>
                                            <div class="teacherDesc">
                                                <p>{val.teacherOutline}</p>
                                            </div>
                                            <div class="teacherDescInfo">教师资格证编号：{val.teaQualificationCodestr}</div>
                                        </div>
                                    </li>
                                    )
                        })}
                    </ul>
                </div>
            </div>
        </div>
        )
    }
}
export default TeacherTeam;