import React , {
    useState,
    useReducer,
    useContext,
    useLayoutEffect,
    useEffect,
    useRef,
    memo,
    useMemo,
    useCallback,
}      from 'react';
import { connect } from 'react-redux'
import {update} from "../../redux/user_redux";
import './index.styl'
import AwesomeSwiper from 'react-awesome-swiper';
const config = {
    // width: 980,
    loop : true,
    autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
    },
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    speed: 1000,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        // bulletElement : 'li',
        // hideOnClick :true,
        clickable :true,
    },
    // on: {
    //     slideChange: function () {

    //     },
    // },
};
const configMobile = {
    // width: 360,
    loop : true,
    autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: true,
    },
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    speed: 1000,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        // bulletElement : 'li',
        // hideOnClick :true,
        clickable :true,
    },
}
@connect(
    state=>state.user,
    {update}
)

class Home extends React.Component {
    swiperRef = null;
    goNext = () => {//use `this.swiperRef.swiper` to get the instance of Swiper
        this.swiperRef.swiper.slideNext();
    }    
    render() {
        const isMobile = this.props.data.isMobile
        let BANNER = <img className='index_01' src={require('./images/index_banner.png')} alt=""/>
        if(isMobile) {
            BANNER = <div className='banner_home_mobile'>
                        <div className="text">
                            <div className="text_1">5-12岁 数学思维训练体系</div>
                            <div className="text_2">在线6人小班直播教学 “教”与“学”更高效</div>
                        </div>
                    </div>
        }
        return (
            <div className='clearfix index_container'>
                {BANNER}
                <div class={isMobile?"bg_f4_mobile":"bg_f4"}>
                    <div class="container">
                        <h5 class={isMobile?'bigTitle_mobile':'bigTitle'}>线上6人小班互动教学</h5>
                        <img src={require('./images/index_01.png')} alt=""/>
                    </div>  
                </div>
                <div class={isMobile?"bg_fff_mobile":"bg_fff"}>
                    <div class="container">
                        <h5 class={isMobile?'bigTitle_mobile':'bigTitle'}>因材施教 分层设计 分级培养</h5>
                        <p class="smTitle">为不同水平、年级的孩子，适配不同难度，不同级别的课程内容</p>
                        <img src={require('./images/index_02.png')} alt=""/>
                    </div>  
                </div>
                <div class={isMobile?"bg_f4_mobile":"bg_f4"}>
                    <div class="container">
                        <h5 class={isMobile?'bigTitle_mobile':'bigTitle'}>科学的学习闭环 循环巩固 螺旋上升</h5>
                        <img src={require('./images/index_03.png')} alt=""/>
                    </div>  
                </div>
                <div class={isMobile?"bg_fff_mobile":"bg_fff"}>
                    <div class="container">
                        <h5 class={isMobile?'bigTitle_mobile':'bigTitle'}>了解学生 钻研教育的教研团队</h5>
                        <p class="smTitle">十年磨一剑 只为因材施教</p>
                        <img src={require('./images/index_04.png')} alt=""/>
                    </div>  
                </div>
                <div class={isMobile?"bg_f4_mobile":"bg_f4"}>
                    <div class="container">
                        <h5 class={isMobile?'bigTitle_mobile marBot30':'bigTitle marBot80'}>我们的服务承诺</h5>
                        <img src={require('./images/index_05.png')} alt=""/>
                    </div>  
                </div>
                {/*<div class={isMobile ? "bg_fff-mobile" : "bg_fff"}>
                    <div class={isMobile?"container home_container_mobile":"container home_container"}>
                        <h5 class={isMobile?'bigTitle_mobile':'bigTitle'}>用户反馈</h5>
                        <p class="smTitle marBot80">用户的认可，是我们前行的动力</p>
                        <AwesomeSwiper ref={this.swiperRef = 'ref'} config={isMobile?configMobile:config}>
                            <div class="swiper-wrapper">
                                <div class="swiper-slide"> 
                                    <div class="img-wrapper">
                                        <img src={isMobile ? require('./fb1.png') : require('./images/swp_01.png')} alt=""/>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="img-wrapper">
                                        <img src={isMobile ? require('./fb2.png') : require('./images/swp_02.png')} alt=""/>
                                    </div>
                                </div>
                                <div class="swiper-slide"> 
                                    <div class="img-wrapper">
                                        <img src={isMobile ? require('./fb3.png') : require('./images/swp_03.png')} alt=""/>
                                    </div>
                                </div>
                                <div class="swiper-slide"> 
                                    <div class="img-wrapper">
                                        <img src={isMobile ? require('./fb4.png') : require('./images/swp_04.png')} alt=""/>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="img-wrapper">
                                        <img src={isMobile ? require('./fb5.png') : require('./images/swp_05.png')} alt=""/>
                                    </div>
                                </div>
                                <div class="swiper-slide"> 
                                    <div class="img-wrapper">
                                        <img src={isMobile ? require('./fb6.png') : require('./images/swp_06.png')} alt=""/>
                                    </div>
                                </div>                                
                            </div>
                            {!isMobile && <div class="swiper-button-prev"></div>}
                            {!isMobile && <div class="swiper-button-next"></div>}
                            <div class="swiper-pagination"></div>
                        </AwesomeSwiper>
                    </div>  
                </div>*/}
            </div>
        )
    }
}
export default Home


