import  React  from 'react'
import {withRouter} from "react-router-dom";

import { Menu, Dropdown, Icon } from 'antd';

import './index.css'

const menu = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://ps.stu.52shuxue.com">
        华师365小学
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://cz.stu.52shuxue.com">
        华师365初中
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://gz.stu.52shuxue.com">
        华师365高中
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://hx.stu.52shuxue.com">
        宏星小学
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="https://yz.stu.52shuxue.com">
        养正初中
      </a>
    </Menu.Item>
  </Menu>
);

@withRouter
class TopNav extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            navList:[
                {
                    tap:'/',
                    name:'首页',
                },
                {
                    tap:'/teacher',
                    name:'教研团队',
                },
                {
                    tap:'/problem',
                    name:'常见问题',
                },
                {
                    tap:'/software',
                    name:'软件下载',
                },
                {
                    tap:'/about',
                    name:'关于我们',
                },
            ],
            active:'首页',
        };
        this.handleClickNav = this.handleClickNav.bind(this);
        this.handleScroll  =  this.handleScroll.bind(this);
    }
    handleClickNav(name,link){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.setState({
                active:name
            }
        );
       this.props.history.push(link);
       this.props.onChange({name:name})
    }
    handleScroll(){
        // console.log(window.scrollY,2333)
    }
    componentDidMount() {
        let routers = this.props.history.location.pathname;
        if(routers==='/'){
            this.setState({
                active:'首页'
            })
        }else if(routers.indexOf('/problem')>=0){
            this.setState({
                active:'常见问题'
            })
        }else if(routers==='/software'){
            this.setState({
                active:'软件下载'
            })
        } else if(routers.indexOf('/teacher')>=0){
            this.setState({
                active:'教研团队'
            })
        } else if(routers==='/about'){
            this.setState({
                active:'关于我们'
            })
        } else if(routers==='/course'){
            this.setState({
                active:'课程介绍'
            })
        }                 
        
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let routers = this.props.history.location.pathname;
        if(routers==='/'){
            this.setState({
                active:'首页'
            })
        }else if(routers.indexOf('/problem')>=0){
            this.setState({
                active:'常见问题'
            })
        }else if(routers==='/software'){
            this.setState({
                active:'软件下载'
            })
        } else if(routers.indexOf('/teacher')>=0){
            this.setState({
                active:'教研团队'
            })
        } else if(routers==='/about'){
            this.setState({
                active:'关于我们'
            })
        } else if(routers==='/course'){
            this.setState({
                active:'课程介绍'
            })
        } 
        window.addEventListener('scroll', this.handleScroll);
    }

    render(){
        return(
            <ul className='nav clearfix'>
                {
                    this.state.navList.map((value) => {
                       return(
                           <li className='pull-left ' key={value.name}>
                               <span onClick={()=>this.handleClickNav(value.name,value.tap)} className={value.name === this.state.active ? 'active':''}>{value.name}</span>
                           </li>
                       )
                    })
                }
                <li className='pull-left'>
                  <Dropdown overlay={menu} trigger="click" overlayStyle={{"zIndex": "99999999"}}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{color: "white"}}>
                      华师东方数学测评 <Icon type="down" />
                    </a>
                  </Dropdown>
                </li>
                <li className='pull-left'>
                  <a href="http://matheasy.cn/spdb/user/login/" style={{'color': 'white'}} target="_blank">数学点点通</a>
                </li>
              </ul>
        )
    }
}
export default TopNav;