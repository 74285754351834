import React            from 'react';
import {  Switch, Redirect, Route } from 'react-router-dom';
import TeacherTeam from './index'
import TeacherInfo from './teacherInfo/index'
class TeacherTeamRouter extends React.Component{
    render() {
        return (
           <Switch>
               <Route path="/teacher/index" component={TeacherTeam}/>
               <Route path="/teacher/teacherinfo/:teacherId?" component={TeacherInfo}/>
               <Redirect exact from="/teacher" to="/teacher/index" />
           </Switch>
        )
    }
}
export default TeacherTeamRouter