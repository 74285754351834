import { postData } from "../utils/httpset";
import { getLocalStorage } from "../utils/utils";
export const GET_EVALEVEL_SET = 'GetEvalLevelSet';
export const ERROR_MSG = 'ERROR_MSG';
const initState={
    redirectTo:'',
    msg:'',
    user:'',
    type:'',
    data:{
        isMobile: navigator.userAgent.match(/(iPhone|iPod|Android|iPad)/i),  //navigator.userAgent.match(/(iPhone|iPod|Android|ios|iPad|AppleWebKit.*Mobile.*)/i),
    }

}
function authSuccess(data){
    return {type: GET_EVALEVEL_SET, payload:data}
}
function errorMsg(msg){
    return { msg, type:ERROR_MSG }
}

export function user(state=initState, action){
    switch(action.type){
        case GET_EVALEVEL_SET:
            return { ...state,msg:'',...action.payload}
            case ERROR_MSG:
            return {...state, isAuth:false, msg:action.msg}
        default:
            return state
    }
}

export function update(){
    return dispatch=>{
        const token = getLocalStorage('token');
        const memberName = getLocalStorage('memberName');
        postData(`/eval/common/getTeacherInfoList`,{
            teacher_info_ids:'12,22,16,18,64,23'
        })
            .then(res=>{
                if (res.status===200) {
                    dispatch(authSuccess(res.data))
                }else{
                    dispatch(errorMsg(res.data.meta))
                }
            })
    }
}