import axios from 'axios';
import qs from 'qs';
import 'whatwg-fetch';
import 'es6-promise';
import { message } from 'antd'
import {testUrl} from './utils'


/* eslint-disable */
// 拦截请求
axios.interceptors.request.use((config) => {
    // message.info('加载中',10);
    // setTimeout(() => {
    //   tryHideFullScreenLoading();
    // }, 500);
    return config;
});
axios.interceptors.response.use((config) => {
    // if (config.meta.errCd=="") {
    setTimeout(() => {
       message.destroy();
    }, 500);
    // }
    return config;
});
// 拦截相应

/* eslint-disable */
// 发送 post 请求
export function post(url, paramsObj) {
    const root = process.env.API_ROOT;
    const configUrl = `${root}/${url}`;
    const result = fetch(configUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
        },
        body: paramsObj,
    });
    return result;
}

// 默认端口
// `transformRequest` 允许在向服务器发送前，修改请求数据
// 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
// 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
axios.defaults.transformRequest = [(data) => {
    const result = JSON.stringify(data);
    return result;
}];
axios.defaults.baseURL = testUrl;
// 请求超时(0表示无超时时间)
// 请求超时超过"timeout"的时间，请求将被终端
axios.defaults.timeout = 20000;

axios.defaults.withCredentials = false;


axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;Accept-Language:zh-CN,zh;q=0.8';

export const getData = (url, param) => {
    const root = process.env.API_ROOT;
    const configUrl = `${url}`;
    axios.defaults.responseType = 'json';
    const result = axios.get(`${configUrl}`, param);
    return result;
};
export const postData = (url, param) => {
    axios.defaults.transformRequest = [(data) => {
        const result = JSON.stringify(data);
        return result;
    }];
    axios.defaults.responseType = 'json';
    const root = process.env.API_ROOT;
    const configUrl = `${url}`;
    const result = axios.post(`${configUrl}`, param);
    return result;
};
export const postDataDownLoad = (url, param) => {
    axios.defaults.transformRequest = [(data) => {
        const result = JSON.stringify(data);
        return result;
    }];
    axios.defaults.responseType = 'blob';
    const root = process.env.API_ROOT;
    const configUrl = `${url}`;
    const result = axios.post(`${configUrl}`, param);
    return result;
};

export const allData = (arr) => {
    const result = axios.all(arr);
    return result;
};

export const spreadCallBack = (func) => {
    const result = axios.spread(func);
    return result;
};
export const uploadFile = (url, params) => {
    axios.defaults.transformRequest = [(data) => {
        const dataString = data;
        return dataString;
    }];
    axios.defaults.responseType = 'json';
    axios.defaults.withCredentials = true;
    const root = process.env.API_ROOT;
    const configUrl = `${url}`;
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    const result = axios.post(configUrl, params, config);
    return result;
};
export const postPhp = (url, params) => {
    axios.defaults.transformRequest = [(data) => {
        const dataString = qs.stringify(data);
        return dataString;
    }];
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;Accept-Language:zh-CN,zh;q=0.8',
        },
    };
    const configUrl = `${url}`;
    const result = axios.post(configUrl, params, config);
    return result;
};