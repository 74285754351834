import React from 'react'
import './lookcourse.css'
import { connect } from 'react-redux'
import {update} from "../../../redux/user_redux";
@connect(
    state=>state.user,
    {update}
)
class IosPreview extends React.Component{
    render(){
        const isMobile = this.props.data.isMobile
        return (
            <div class={isMobile?'container_mobile_iospreview':'container'}>
                <img id="img-set" src={require('./images/iospreview.png')} alt=""/>
            </div>
        )
    }

}
export default IosPreview