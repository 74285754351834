import React from 'react'
import { withRouter } from "react-router-dom";
import './index.css'
// @withRouter
class CommonFooter extends React.PureComponent {
    constructor() {
        super();
        this.clickHandle = this.clickHandle.bind(this);
        this.clickPic = this.clickPic.bind(this);
        this.state = {
            showCn: false,
            issn:'com'
        }
    }
    clickHandle(clickPage) {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.props.history.push(clickPage);
    }
    clickPic() {
        window.location.href = 'http://beian.miit.gov.cn';
    }
    componentDidMount(){
        this.isHasCn();
    }
    isHasCn(){
        let domainFlag = document.domain;
        if(domainFlag.indexOf('cn') > -1){
            this.setState({ showCn: true,issn:'cn' })
        }else{
            this.setState({ showCn: false,issn:'com' }) 
        }
    }
    render() {
        return (
            <div id="footer">
                <div className="container">
                    <ul className='clearboth footer-list'>
                        <li className='pull-left text-left'>
                            <p className='sub-title'>52数学思维</p>
                            <p className='footer-sub-common click-page' onClick={() => this.clickHandle('/')}>首页</p>
                            <p className='footer-sub-common  click-page' onClick={() => this.clickHandle('/teacher/index')}>教研团队</p>
                            <p className='footer-sub-common  click-page' onClick={() => this.clickHandle('/problem/index')}>常见问题</p>
                            <p className='footer-sub-common  click-page' onClick={() => this.clickHandle('/software')}>软件下载</p>
                            <p className='footer-sub-common  click-page' onClick={() => this.clickHandle('/about')}>关于我们</p>
                        </li>
                        <li className='pull-left'>
                            <p className='sub-title'>联系我们</p>
                            <p className='footer-sub-common'>举报和投诉：<br />
                                021-63178899 &nbsp;&nbsp;&nbsp;&nbsp; 13120811785</p>
                            <p className='footer-sub-common'>地址：<br />
                                上海市静安区愚园路309号紫安大厦13F</p>
                            <p className='footer-sub-common'>邮箱：<br />
                                yang.yuan@fluxedu.com</p>
                        </li>
                        <li className='pull-right text-right'>
                            <p className='sub-title'>微信服务号</p>
                            <p className='footer-sub-common'><img src={require('./qrcode.jpg')} alt="" /></p>
                        </li>
                    </ul>
                </div>
                <div id="foot-wraper">
                    <div className="footer-common clearboth">Copyright © 2021 52数学思维  版权所有 www.52shuxue.{this.state.issn} All Rights Reserved </div>
                    <div className="footer-common clearboth licence">
                        <span><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备16005585号-3</a></span>
                        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602003868"><img src={require('./gh.png')}/>沪公网安备 31010602003868号</a>
                        <span><a href="https://tars.52shuxue.com/fluxedulicense/businesslicense.jpg" target="_blank">ICP经营许可证 沪B2-20200169</a></span>
                        <span><a href="https://tars.52shuxue.com/fluxedulicense/broadcastinglicense.jpg" target="_blank">广播电视节目制作经营许可证(沪)字第03725号</a></span>
                    </div>
                </div>
            </div>
        )
    }

}
export default CommonFooter