import React from 'react'
import './header.css'
import TopNav from '../topNav'
class CommonHeader extends React.Component{
    constructor(props){
        super(props);
        // console.log(props)
        this.state = {
            username: '',
            isFixed:false,
            showNav:true,
            isPro:false,
        }
    }
    componentDidMount(){
        window.addEventListener('scroll', this.bindHandleScroll.bind(this),true);
        var localhref=window.location.href
        if(localhref.indexOf('/problem')>=0 || localhref.indexOf('/course')>=0){
            this.setState({
                isPro: true 
            })            
        }else{
            this.setState({
                isPro: false 
            })
        }
    }
    bindHandleScroll(event){
        let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
        //控制元素块A随鼠标滚动固定在顶部
        if(this.state.isPro){
            return false
        }
        if (scrollTop >= 600) {
          this.setState({ isFixed: true })
          this.setState({ showNav: true })
        } else if (scrollTop < 600) {
            if(scrollTop>70){
                this.setState({ showNav: false })
            }else{
                this.setState({ showNav: true })
            }
          this.setState({ isFixed: false })
        }          
    }

    onChangeState(stateName){
        if(stateName.name=='常见问题' || stateName.name=='课程介绍'){
            this.setState({
                isPro: true 
            })
        }else{
            this.setState({
                isPro: false 
            })
        }
    }
    render(){
        return(
            <div className={`header ${this.state.isFixed ? 'fixed' : ''} ${this.state.isPro ? 'isPro' : ''}`} style={{display:this.state.showNav?'block':'none'}}>
                   <div className="container clearfix">
                       <div className="pull-left"><img src={require('./logo.png')} alt=""/></div>
                       <div className="pull-left">
                           <TopNav  onChange={this.onChangeState.bind(this)}/>
                       </div>
                   </div>
            </div>
        );
    }
}
export default CommonHeader