import React from 'react'
import './header.css'
import TopNavMobile from '../topNavMobile'
class CommonHeader extends React.Component{
    constructor(props){
        super(props);
        // console.log(props)
        this.state = {
            username: '',
            isFixed:false,
            showNav:true,
            isPro:false,
        }
    }
    componentDidMount(){
        window.addEventListener('scroll', this.bindHandleScroll,true);
        // console.log('成功绑定了scroll事件')
        var localhref=window.location.href
        if(localhref.indexOf('/problem')>=0){
            this.setState({
                isPro: true 
            })            
        }else{
            this.setState({
                isPro: false 
            })
        }
    }
    bindHandleScroll = () => {
        // console.log('成功触发了滚动事件')
        // let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop)
        let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        // console.log('scrollTop----------'+scrollTop)
        //控制元素块A随鼠标滚动固定在顶部
        if(this.state.isPro){
            // console.log('ispro=true')
            return false
        }
        if (scrollTop >= 130) {
          this.setState({ isFixed: true })
          this.setState({ showNav: true })
        } else if (scrollTop < 130) {
            if(scrollTop>30){
                this.setState({ showNav: false })
            }else{
                this.setState({ showNav: true })
            }
          this.setState({ isFixed: false })
        }          
    }
    // 移动端课程介绍下去掉起始位置固定的蓝色头部
    onChangeState(stateName){
        // console.log(this)
        if(stateName.name=='常见问题'){
            this.setState({
                isPro: true 
            })
        }else{
            this.setState({
                isPro: false 
            })
        }
    }
    render(){
        // console.log(this.props)
        return(
            <div className={`header ${this.state.isFixed ? 'fixed' : ''} ${this.state.isPro ? 'isPro' : ''}`} style={{display:this.state.showNav?'block':'none'}}>
                <div className="wrap">
                    <div className="logo"><img src={require('./logo.png')} alt=""/></div>
                    <div className="nav_component">
                        <TopNavMobile  onChange={this.onChangeState.bind(this)}/>
                    </div>
                </div>
            </div>
        );
    }
}
export default CommonHeader