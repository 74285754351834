import React from 'react'
import './index.css'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import {update} from "../../redux/user_redux";

@connect(
    state=>state.user,
    {update}
)

class Course extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isMobile: this.props.data.isMobile,
            tabs:[
                {tabName:"Lv 1",id:1},
                {tabName:"Lv 2",id:2},
                {tabName:"Lv 3",id:3},
                {tabName:"Lv 4",id:4},
                {tabName:"Lv 5",id:5},
                {tabName:"Lv 6",id:6},
                {tabName:"Lv 7",id:7},
                {tabName:"Lv 8",id:8},
                {tabName:"Lv 9",id:9},
            ],
            currentIndex:1,
            tabsLevel:[
                {tabName:"L1",id:1},
                {tabName:"L2",id:2},
                {tabName:"L3",id:3},
                {tabName:"L4",id:4},
                {tabName:"L5",id:5},
                {tabName:"L6",id:6},
                {tabName:"L7",id:7}
                // {tabName:"L8",id:8},
                // {tabName:"L9",id:9},                
            ],
            currentIndexLevel:1,
            showQr:false,
            courseTab:false,
            courseObj: null
        };
        this.handleEnter = this.handleEnter.bind(this);
        this.handleOut = this.handleOut.bind(this);
        this.changeCouse = this.changeCouse.bind(this);
    }
    tabChoiced=(id)=>{
        this.setState({
            currentIndex:id
        })
    }
    tabLevel=(id)=>{
        this.setState({
            currentIndexLevel:id
        })
    }
    changeCouse(courseTab){
        if(courseTab=='one'){
            this.setState({
                courseTab:true
            })
        }else{
            this.setState({
                courseTab:false
            })
        }
    }
    handleEnter(type){
        this.setState({
            showQr:true,
        })
    }
    handleOut(type){
        this.setState({
            showQr:false,
        })
    }
    
    render(){
        var _this=this;
        var tabList= this.state.tabs.map(function(res,index) {
            var tabStyle=res.id==this.state.currentIndex ? 'subnormal active' : 'subnormal';
            return    <li key={index} onClick={this.tabChoiced.bind(_this,res.id)} className={tabStyle}>{res.tabName}</li>
        }.bind(_this));
        var tabLevelList= this.state.tabsLevel.map(function(res,index) {
            var tabStyle=res.id==this.state.currentIndexLevel ? 'normal active' : 'normal';
            return    <span key={index} onClick={this.tabLevel.bind(_this,res.id)} className={tabStyle}>{res.tabName}</span>
        }.bind(_this));      
        const isMobile = this.state.isMobile
        // console.log(this)
        // let COURSE = ''
        // if(isMobile) {
        //     COURSE = ''
        // }
        const {courseObj} = this.state
        return(
            <div class={isMobile?"course_mobile":"course"}>
                <div class="bg_fff">
                    <div class="container clearfix">
                        {/* <div class="ban"></div> */}
                        <img src={isMobile?require('./images/bg_mobile.png'):require('./images/bg.png')} alt="" class="ban"/>
                        <div class="content">
                            <p class="courseName">52数学思维6人小班直播课</p>
                            <p class="colorOrange">小学数学思维课，真人在线直播，6人小班互动，教与学更高效！</p>
                            <div class="moneyMain" style={{"display":this.state.courseTab? 'block' : 'none'}}>
                                <span class="colorOrange">¥ </span><span class="money colorOrange"> 3720</span>{/*<span class="linethrough">原价：¥3720/30课时</span>*/}
                            </div>
                            <div class="moneyMain" style={{"display":this.state.courseTab? 'none' : 'block'}}>
                                <span class="colorOrange">¥ </span><span class="money colorOrange"> 5940</span>{/*<span class="linethrough">原价：¥7440/60课时</span>*/}
                            </div>
                            <div class="level">
                                <span>选择年级</span>
                                {tabLevelList}
                            </div>
                            <div class="level">
                                <span>选择课时</span>
                                <span className={`courseNormal ${this.state.courseTab ? 'active' : ''}`} onClick={()=>this.changeCouse('one')}>30课时</span>
                                <span className={`courseNormal ${this.state.courseTab ? '' : 'active'}`} onClick={()=>this.changeCouse('two')}>60课时</span>
                            </div>
                            <div class="flex qrcodeMain">
                                <div style={{"display":this.state.showQr?"block":"none"}} class="qrcode">
                                    <img src={require('./images/qrcode.jpg')} alt="" width="100%"/>
                                </div>
                                <span class="zixunbtn" onMouseEnter={this.handleEnter} onMouseLeave={this.handleOut}>课程咨询</span>
                                <Link to="/problem/backrule">
                                    <div>详细退费规则</div>
                                </Link>
                            </div>
                        </div>
                    </div>  
                </div>                
                <div class="bg_f4">
                    <div class="container_w750">
                        <p>52数学思维专为<span class="colorOrange">6~15岁</span>中小学生设计，课程以<span class="colorOrange">数学思维训练</span>和<span class="colorOrange">中考数学高分冲刺</span>为目标，分L1-L9九级课程体系，采⽤线上6⼈小班直播互动的模式授课，通过精⼼设计的课程内容、深⼊浅出的讲解、丰富的线上互动提供针对每个孩子的个性化服务，让数学的学习变得更简单、更有乐趣。</p>
                    </div>  
                </div>
                <div class="bg_fff">
                    <div class="container">
                        <h5 class="bigTitle ">因材施教 分层设计 分级培养</h5>
                        <p class="smTitle marBot80">为不同水平、年级的孩子，适配不同难度，不同级别的课程内容</p>
                        <img src={require('./images/course_01.png')} alt="" width="100%"/>
                    </div>
                </div>
                <div class={isMobile?"bg_f4_mobile":"bg_f4"}>
                    <div class="container">
                        <h5 class={!isMobile ? 'bigTitle' : 'bigTitle_mobile'}>课程特色</h5>
                        {!isMobile && <img src={require('./images/course_02.png')} alt="" width="100%"/>}
                        {!isMobile && <img src={require('./images/course_03.png')} alt="" width="100%"/>}
                        {isMobile && <img src={require('./cr1.png')} alt="" width="100%"/>}
                        {isMobile && <img src={require('./cr2.png')} alt="" width="100%"/>}
                        {isMobile && <img src={require('./cr3.png')} alt="" width="100%"/>}
                        {isMobile && <img src={require('./cr4.png')} alt="" width="100%"/>}
                    </div>
                </div>
                {/*<div class="bg_fff">
                    <div class="container">
                        <h5 class="bigTitle ">课程大纲</h5>
                        <ul class={!isMobile ? 'subNavWrap' : 'subNavWrap subNavWrap_mobile'}>
                            {tabList}
                        </ul>
                        {courseObj && <div className="newsList">
                            <div style={{"display":this.state.currentIndex==1 ? 'block' : 'none'}} >
                                <img src={!isMobile ? courseObj.pc[0] : courseObj.mobile[0]} alt="" width="100%"/>
                            </div>
                            <div style={{"display":this.state.currentIndex==2 ? 'block' : 'none'}}>
                                <img src={!isMobile ? courseObj.pc[1] : courseObj.mobile[1]} alt="" width="100%"/>
                            </div>
                            <div style={{"display":this.state.currentIndex==3 ? 'block' : 'none'}}>
                                <img src={!isMobile ? courseObj.pc[2] : courseObj.mobile[2]} alt="" width="100%"/>
                            </div>
                            <div style={{"display":this.state.currentIndex==4 ? 'block' : 'none'}}>
                                <img src={!isMobile ? courseObj.pc[3] : courseObj.mobile[3]} alt="" width="100%"/>
                            </div>
                            <div style={{"display":this.state.currentIndex==5 ? 'block' : 'none'}}>
                                <img src={!isMobile ? courseObj.pc[4] : courseObj.mobile[4]} alt="" width="100%"/>
                            </div>
                            <div style={{"display":this.state.currentIndex==6 ? 'block' : 'none'}}>
                                <img src={!isMobile ? courseObj.pc[5] : courseObj.mobile[5]} alt="" width="100%"/>
                            </div>
                            <div style={{"display":this.state.currentIndex==7 ? 'block' : 'none'}}>
                                <img src={!isMobile ? courseObj.pc[6] : courseObj.mobile[6]} alt="" width="100%"/>
                            </div>
                            <div style={{"display":this.state.currentIndex==8 ? 'block' : 'none'}}>
                                <img src={!isMobile ? courseObj.pc[7] : courseObj.mobile[7]} alt="" width="100%"/>
                            </div>
                            <div style={{"display":this.state.currentIndex==9 ? 'block' : 'none'}}>
                                <img src={!isMobile ? courseObj.pc[8] : courseObj.mobile[8]} alt="" width="100%"/>
                            </div>
                        </div>}
                    </div>
        </div>*/}
            </div>
        )
    }

    componentDidMount(){
        this.setState({
            courseObj: {...window.course}
        })
    }
    /*componentDidUpdate(prevProps, prevState, snapshot) {
    }*/
}
export default Course