import React from 'react'
import './index.css'
import { connect } from 'react-redux'
import {update} from "../../redux/user_redux";

@connect(
    state=>state.user,
    {update}
)

class About extends React.Component{
    constructor(){
        super();
    }

    render(){
        const isMobile = this.props.data.isMobile
        let BANNER = <img className='index_01' src={require('./images/about_banner.png')} alt=""/>
        let CHAOS = <div class="bg_f4">
                        <div class="container">
                            <h5 class="bigTitle marBot80">我们的文化</h5>
                            <div class="about_srp">
                                <img src={require('./images/about_02.png')} alt=""/>
                                <div class="about_srp_text">
                                    <p class="smtit">使命</p>
                                    <p>让数学学习更容易</p>
                                </div>
                            </div>
                            <div class="about_srp">
                                <div class="about_srp_text">
                                    <p class="smtit">愿景</p>
                                    <p>通过科技与教育的深度融合，成为行业最具影响力的互联网教育公司</p>
                                </div>
                                <img src={require('./images/about_03.png')} alt=""/>
                            </div>
                            <div class="about_srp">
                                <img src={require('./images/about_04.png')} alt=""/>
                                <div class="about_srp_text">
                                    <p class="smtit">价值观</p>
                                    <p><span>客户第一</span><span>创新</span><span>自省</span></p>
                                </div>
                            </div>
                        </div>  
                    </div>
        if(isMobile) {
            BANNER = <div className='banner_about_mobile'>
                        <div className="text">
                            <div className="text_1">专注中小学数学教育的互联网科技公司</div>
                        </div>
                    </div>
            CHAOS = <div class="bg_f4">
                        <div class="container">
                            <h5 class="bigTitle">我们的文化</h5>
                            <div class="about_srp">
                                <div class="about_srp_text">
                                    <p class="smtit">使命</p>
                                    <p>让数学学习更容易</p>
                                </div>
                                <img class="sp_img" src={require('./images/about_02.png')} alt=""/>
                            </div>
                            <div class="about_srp">
                                <div class="about_srp_text">
                                    <p class="smtit">愿景</p>
                                    <p>通过科技与教育的深度融合，成为行业最具影响力的互联网教育公司</p>
                                </div>
                                <img class="sp_img" src={require('./images/about_03.png')} alt=""/>
                            </div>
                            <div class="about_srp">
                                <div class="about_srp_text">
                                    <p class="smtit">价值观</p>
                                    <p><span>客户第一</span><span>创新</span><span>自省</span></p>
                                </div>
                                <img class="sp_img" src={require('./images/about_04.png')} alt=""/>
                            </div>
                        </div>  
                    </div>
        }
        return(
            <div class={isMobile?'aboutMobile':'about'}>
                {BANNER}
                <div class="bg_f4">
                    <div class="container_w750">
                        <p>弗兮网络成立于2014年，是四季教育（上市公司）投资，专注于中小学数学教育的互联网科技公司。拥有强大的学科教研和互联网科技综合实力，一直致力于将优势的传统教育资源转变为领先的互联网教育产品。</p>
                    </div>  
                </div>
                <div class="bg_fff">
                    <div class="container_w750">
                        <h5 class={isMobile?"bigTitle marBot30":"bigTitle marBot80"}>我们的产品</h5>
                        <p>“52数学思维”是弗兮网络为学有余力的中小学生精心打造的数学能力提升课程，采用全线上6人小班模式授课，通过精心设计的课程体系、深入浅出的讲解、丰富的线上互动提供针对每个孩子的个性化服务，让数学的学习变得更简单、更有乐趣。</p>
                        <p>“52数学思维”课程服务基于弗兮网络创新的智能数学学习系统 FluxMath。</p>
                        <div class="about_module">
                            <img src={require('./images/about_01.png')} alt=""/>
                            <div>
                                <p>通过对一流教师教学过程和学生学习大数据的分析，FluxMath 构建了以智能测评为中心的“教·学·练·测”高效循环，帮助学生以较小代价实现数学能力的不断提升。</p>
                                <p>学生通过智能测评可以快速找到学习短板，进而通过系统提供的强化学习模式快速补强；而对学生掌握较好的知识点和题型则会推荐适当的进阶知识，持续开发其学习潜力。</p>
                            </div>
                        </div>
                        <p>基于多年积累的教研教学经验和不断增强的学习大数据，FluxMath 正变得越来越智能，力求带给每位孩子更个性化、效果更好、效率更高的学习体验。
</p>
                    </div>  
                </div> 
                {CHAOS}
                <div class="bg_fff">
                    <div class="container">
                        <h5 class="bigTitle ">我们的团队</h5>
                        <p class={isMobile?"smTitle marBot30":"smTitle marBot80"}>人才是我们最宝贵的财富，我们高度重视员工的发展</p>
                        <img src={require('./images/about_05.png')} alt="" width="100%"/>
                        <div class="about_team">
                            <p>科技与教育，让我们更加关注员工自身成长</p>
                            <p>扁平的治理结构，让员工充分发挥才干</p>
                            <p>职级体系，为员工提供清晰的发展路径</p>
                        </div>
                    </div>  
                </div>

            </div>
        )
    }

    componentDidMount(){
    }
    /*componentDidUpdate(prevProps, prevState, snapshot) {
    }*/
}
export default About