import { Icon } from 'antd';
import  React  from 'react'
import {withRouter} from "react-router-dom";
import './index.css'
import {CSSTransition} from 'react-transition-group'
@withRouter
class TopNav extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            navList:[
                {
                    tap:'/',
                    name:'首页',
                },
                {
                    tap:'/teacher',
                    name:'教研团队',
                },
                {
                    tap:'/problem',
                    name:'常见问题',
                },
                {
                    tap:'/software',
                    name:'软件下载',
                },
                {
                    tap:'/about',
                    name:'关于我们',
                },
            ],
            active:'首页',
            showMobileNav: false
        };
        this.handleClickNav = this.handleClickNav.bind(this);
        this.handleScroll  =  this.handleScroll.bind(this);
    }
    handleDisplayNav (event) {
        // console.log(event)
        this.stopPropagation(event);
        
        if(!this.state.showMobileNav) {
            this.setState({showMobileNav:true},
                ()=> {
                    document.getElementById('nav_modal').addEventListener('touchmove', (e) =>{
                        e.preventDefault()
                    },{
                        passive: false
                    })
                })
            
        }else {
            this.setState({showMobileNav:false})
        }
    }
    handleClickNav(name,link){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.setState({
                active:name
            }
        );
       this.props.history.push(link);
       this.props.onChange({name:name})
    }
    handleScroll(){
        // console.log(window.scrollY,2333)
    }
    hideAllMenu = () => {
        this.setState({
            showMobileNav: false
        })
    }
    stopPropagation(e) {
        // console.log(e)
        e.nativeEvent.stopImmediatePropagation();
    }
    componentDidMount() {
        document.addEventListener('click', this.hideAllMenu);
        let routers = this.props.history.location.pathname;
        if(routers==='/'){
            this.setState({
                active:'首页'
            })
        }else if(routers.indexOf('/problem')>=0){
            this.setState({
                active:'常见问题'
            })
        }else if(routers==='/software'){
            this.setState({
                active:'软件下载'
            })
        } else if(routers.indexOf('/teacher')>=0){
            this.setState({
                active:'教研团队'
            })
        } else if(routers==='/about'){
            this.setState({
                active:'关于我们'
            })
        } else if(routers==='/course'){
            this.setState({
                active:'课程介绍'
            })
        }                 
        
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillReceiveProps(nextProps, nextContext) {
        let routers = this.props.history.location.pathname;
        if(routers==='/'){
            this.setState({
                active:'首页'
            })
        }else if(routers.indexOf('/problem')>=0){
            this.setState({
                active:'常见问题'
            })
        }else if(routers==='/software'){
            this.setState({
                active:'软件下载'
            })
        } else if(routers.indexOf('/teacher')>=0){
            this.setState({
                active:'教研团队'
            })
        } else if(routers==='/about'){
            this.setState({
                active:'关于我们'
            })
        } else if(routers==='/course'){
            this.setState({
                active:'课程介绍'
            })
        } 
        window.addEventListener('scroll', this.handleScroll);
    }

    render(){
        let that = this
        let mobileNav = null
        if(this.state.showMobileNav) {
            mobileNav = <CSSTransition
                            in={this.state.showMobileNav}
                            timeout={300}
                            classNames={this.state.showMobileNav?"nav_wrap":"nav_wrap_leave"}
                            unmountOnExit
                        >
                            <div className={this.state.showMobileNav?"nav_wrap":"nav_wrap_leave"} id="nav_modal">
                                {
                                    this.state.navList.map((value) => {
                                        return(
                                            <div className='item' key={value.name}>
                                                <span onClick={()=>this.handleClickNav(value.name,value.tap)} className={value.name === this.state.active ? 'active':''}>{value.name}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </CSSTransition>
        }
        return(
            <div className="navMobile" onClick={(e)=>this.handleDisplayNav(e)}>
                <div className={this.state.showMobileNav?"iconfont www-guanbi":"iconfont www-menu"}></div>
                {mobileNav}
            </div>
        )
    }
}
export default TopNav;