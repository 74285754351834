import React from 'react'
import './index.css'
import { connect } from 'react-redux'
import {update} from "../../redux/user_redux";
@connect(
    state=>state.user,
    {update}
)
class SoftWare extends React.Component{
    constructor(){
        super();
        /*//download.eeo.cn/classin_win_install_2.3.1.415_s.exe*/
        /*//download.eeo.cn/classin_mac_install_2.3.1.415_s.dmg*/
        this.state = {
            downloadWin:'https://videolive.52shuxue.com/download/fluxedu/client/20210412/52shuxue_Windows_v1.11.2.32_20210409.exe',
            downLoadOs:'https://videolive.52shuxue.com/download/fluxedu/client/20210412/52shuxue_Mac_v1.11.2.32_20210409.dmg',
            showQr:false,
            showQrClassin:false,
            downObj: {}
        };
        this.handleClickApp = this.handleClickApp.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleOut = this.handleOut.bind(this);
        this.handleClickAppClassin = this.handleClickAppClassin.bind(this);
        this.handleEnterClassin = this.handleEnterClassin.bind(this);
        this.handleOutClassin = this.handleOutClassin.bind(this);
    }
    handleClickApp(showQr){
        this.setState({
            showQr:!showQr
        })

    }
    handleEnter(){
        this.setState({
            showQr:true
        })
    }
    handleOut(){
        this.setState({
            showQr:false
        })
    }
    handleClickAppClassin(showQrClassin){
        this.setState({
            showQrClassin:!showQrClassin
        })

    }
    handleEnterClassin(){
        this.setState({
            showQrClassin:true
        })
    }
    handleOutClassin(){
        this.setState({
            showQrClassin:false
        })
    }
    render(){
        const isMobile = this.props.data.isMobile
        const {downObj} = this.state
        return(
            <div class={isMobile?'softWareMobile':'softWare'} style={{"paddingBottom":"60px"}}>
                <div class="mask"></div>
                <div class="container clearboth">
                    <div class="download-wrap">
                        <div class="soft-header">
                            客户端下载
                        </div>
                        <div class="clearboth download-set">
                                {/* <p class="soft-header">客户端下载</p>
                                <div class="img-classin">
                                {this.state.showQrClassin ? <div class='qrcode-bg' style={{"display":"block"}}>
                                        <img data-id="classInImage_mdqr"  alt=""/>
                                        <span>扫描二维码下载APP</span>
                                    </div>: <div class='qrcode-bg' style={{"display":"none"}}>
                                    <img data-id="classInImage_mdqr"  alt=""/>
                                    <span>扫描二维码下载APP</span>
                                </div>}
                                </div> */}
                                
                                <div class="btn-classin">
                                    {/* <p class="soft-header">客户端下载</p> */}
                                    <div class='fr'>
                                        {/* <a href="https://apps.apple.com/cn/app/id1557241788" class="ipad-btn"></a> */}
                                        <a href={downObj.ipad} class={isMobile?'ipad-btn-mobile':'ipad-btn'}></a>
                                        <a href={downObj.android} class={isMobile?'android-btn-mobile':'android-btn'}></a>
                                    </div>
                                    <div class='fr'>
                                        <a href={downObj.windows} class={isMobile?'windows-btn-mobile':'windows-btn'}></a>
                                        <a href={downObj.mac} class={isMobile?'mac-btn-mobile':'mac-btn'}></a>
                                    </div>
                                </div>
                                <div class="fc right">
                                    <span>扫描二维码下载APP</span>
                                    <img src={require('./images/qr_mobiledownload.png')} alt=""/>
                                </div>
                        </div>                      
                        {/* <p class="tips">推荐使用谷歌浏览器或火狐浏览器下载 </p>
                        <p class="tips">温馨提示：</p>
                        <p class="tips">下载安装时建议退出本地杀毒软件  </p> */}
                    </div>
                </div>
            </div>
        )
    }
    getEooVersion (){
        var locationProtocol=window.location.protocol;
        var locationHostname='www.eeo.cn';
        if(locationProtocol.indexOf('https')<0){
            locationProtocol='http://';
        }else{
            locationProtocol='https://';
        }
        var locationOrigin=locationProtocol+locationHostname;
        //var version = '1.5.1.30';
        var version = Math.floor((new Date()).getTime()/(1000*60*10));
        var seturlScript=document.createElement('script');
        seturlScript.setAttribute('type','text/javascript');
        seturlScript.setAttribute('charset','UTF-8');
        seturlScript.setAttribute('src',locationOrigin+'/sysshare/custom/seturl_classindownload.js'+'?version='+version);
        document.body.appendChild(seturlScript);
        var invoking=false;
        var invokeClassIn=function (url){
            if(invoking){
                window.alert('正在启动ClassIn');
                return false;
            }else{
                invoking=true;
            }
            var cssList={
                modalView:'width: 270px;height: 160px;position: absolute; z-index:99999; left: 0;right: 0;top: 0;bottom: 0;margin: auto;background: #54a267;border-radius: 5px; box-shadow:3px 3px 3px 0px #ccc;',
                closeBtn:'position: absolute;right: 4px;top: 4px;width: 20px;height: 20px;border-radius: 50%;text-align: center;line-height: 20px;color: white;cursor:pointer;',
                tips:'display:block; text-decoration:none; line-height: 1.5;text-align: center;font-size: 16px;color: #fff; margin-top:60px;'
            }
            var box = document.createElement('div');
            box.setAttribute('style',cssList.modalView);

            var iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src =  url;
            box.appendChild(iframe);

            var closeBtn = document.createElement('div');
            closeBtn.setAttribute('style',cssList.closeBtn);
            closeBtn.innerText = '×';
            closeBtn.onclick = function(){
                invoking=false;
                clearInterval(updateTipsInterval);
                document.body.removeChild(box);
            }
            box.appendChild(closeBtn);

            var tipsText = document.createElement('a');
            tipsText.setAttribute('style',cssList.tips);
            tipsText.innerText = '正在加载，请稍候';
            box.appendChild(tipsText);

            document.body.appendChild(box);
            var waitTime=0;
            var updateTipsInterval=setInterval(function(){
                if(waitTime<5){
                    waitTime++;
                    tipsText.innerText+='.';
                    return false;
                }
                tipsText.href = 'http://eeo.cn';
                tipsText.setAttribute('target','_blank');
                tipsText.innerHTML = '<span style="font-size:12px;">如果启动classIn失败请重新安装</span><br /><b style="font-size:14px;text-decoration:underline;">点击下载ClassIn</b>';
                clearInterval(updateTipsInterval);
            },1000);
        }
        window.invokeClassIn=invokeClassIn;
    }
    componentDidMount(){
        this.getEooVersion();
        this.setState({
            downObj: {...window.downObj}
        })
    }
    /*componentDidUpdate(prevProps, prevState, snapshot) {
        this.getEooVersion();
    }*/
}
export default SoftWare